// Librerias.
import React from "react";

// Components.
import { Navbars, StyledComponents } from "../../components-config";
import { Dashboard } from "./Dashboard";
import {
  ChangePasswordFromUser,
  SuccessChangePasswordFromUser,
} from "./ChangePassword";

// Components for integrity.
import { MealsModule } from "./Cafeteria";
import { SettlementModule, SettlementUserModule } from "./Settlement";
import { ReportModule, ReportQueryModule } from "./Report";
import { IncidentsModule } from "./Incidents";
import { CFDIsModule, CFDIsFileModule} from "./CFDIs";
import { CFDIStampBalanceModule } from "./CFDIs";
import { CmsModule } from "./Cms";

const MainLayout = ({ children }) => {
  return (
    <>
      <Navbars.Dashboard></Navbars.Dashboard>
      {children}
    </>
  );
};

const SecondLayout = ({ title, children, to }) => {
  return (
    <MainLayout>
      <Navbars.CurrentModule to={to} title={title}></Navbars.CurrentModule>
      {children}
    </MainLayout>
  );
};

export const DashboardPage = () => {
  return (
    <>
      <MainLayout>
        <Dashboard></Dashboard>
      </MainLayout>
    </>
  );
};

export const ChangePasswordFromUserPage = () => {
  return (
    <MainLayout>
      <Navbars.CurrentModule title="Cambiar Contraseña"></Navbars.CurrentModule>
      <StyledComponents.SpecialContainer margin="140px 0 0 0">
        <ChangePasswordFromUser></ChangePasswordFromUser>
      </StyledComponents.SpecialContainer>
    </MainLayout>
  );
};

export const SuccessChangePaswordPage = () => {
  return (
    <SecondLayout title="Cambiar Contraseña">
      <StyledComponents.SpecialContainer margin="140px 0 0 0">
        <SuccessChangePasswordFromUser></SuccessChangePasswordFromUser>
      </StyledComponents.SpecialContainer>
    </SecondLayout>
  );
};

// Modulos de cafetería.
export const CafeteriaPage = () => {
  return (
    <>
      <MainLayout>
        <Dashboard></Dashboard>

        <MealsModule />
      </MainLayout>
    </>
  );
};

// Modulos de finiquitos.
export const SettlementsPage = () => {
  return (
    <>
      <MainLayout>
        <Dashboard></Dashboard>
        <SettlementModule />
      </MainLayout>
    </>
  );
};

export const SettlementUserPage = () => {
  return (
    <>
      <MainLayout>
        <Dashboard></Dashboard>
        <SettlementUserModule />
      </MainLayout>
    </>
  );
};

// Modulos de reportes.
export const ReportsPage = () => {
  return (
    <>
      <MainLayout>
        <Dashboard></Dashboard>
        <ReportModule />
      </MainLayout>
    </>
  );
};

// Modulos de reportes.
export const ReportQueryPage = () => {
  return (
    <>
      <MainLayout>
        <Dashboard></Dashboard>
        <ReportQueryModule />
      </MainLayout>
    </>
  );
};

// Modulos Cms.
export const CmsPage = () => {
  return (
    <>
      <MainLayout>
        <Dashboard></Dashboard>
        <CmsModule />
      </MainLayout>
    </>
  );
};


// Modulos de Incidencias.
export const IncidentsPage = () => {
  return (
    <>
      <MainLayout>
        <Dashboard></Dashboard>
        <IncidentsModule />
      </MainLayout>
    </>
  );
};

// Modulos de CFDI's.
export const CFDIsPage = () => {
  return (
    <>
      <MainLayout>
        <CFDIsModule />
      </MainLayout>
    </>
  );
};

export const CFDIsFilePage = () => {
  return(
    <MainLayout>
        <CFDIsFileModule />
      </MainLayout>
  )
}

export const CFDIStampBalancePage = () => {
  return (
    <>
      <MainLayout>
        <CFDIStampBalanceModule />
      </MainLayout>
    </>
  );
};

// Para agregar un nuevo modulos en el selector ir a ./components-integrity/selects linea 161
