// Imports de react.
import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

// Import Google Analytics
import { GoogleAnalytics } from "./components-analitycs";

// Pruebas de comoponentes.

// Páginas de la app.
import { LandingPage } from "./pages";

import {
  ForgotPasswordPage,
  SendEmailPasswordPage,
  ChangePasswordFromEmailPage,
  SuccessChangePasswordFromEmailPage,
} from "./pages/session";

import {
  DashboardPage,
  ChangePasswordFromUserPage,
  SuccessChangePaswordPage,
  CafeteriaPage,
  SettlementsPage,
  SettlementUserPage,
  ReportsPage,
  ReportQueryPage,
  IncidentsPage,
  CFDIsPage,
  CFDIsFilePage,
  CmsPage,
  CFDIStampBalancePage,
} from "./pages/integrity";

function App() {
  useEffect(() => {
    GoogleAnalytics.ReadPage(window.location.pathname + window.location.search);
  }, []);
  return (
    <BrowserRouter>
      <Switch>
        {/* <Route exact path="/debug" component={DebugPage} /> */}
        <Route exact path="/" component={LandingPage} />

        {/* Paginas de sesión */}
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <Route
          exact
          path="/send-email-password"
          component={SendEmailPasswordPage}
        />
        <Route
          exact
          path="/change-password-from-email/:email/:token"
          component={ChangePasswordFromEmailPage}
        />
        <Route
          exact
          path="/success-change-password-from-email"
          component={SuccessChangePasswordFromEmailPage}
        />

        {/* Aquí van modulos de Integrity de dashboard */}
        <Route exact path="/dashboard" component={DashboardPage} />
        {/* <Route exact path="/dashboard/:idCompany/" component={DashboardPage} />
        <Route exact path="/dashboard/:idCompany/:idModule/" component={DashboardPage} />
        <Route exact path="/dashboard/:idCompany/:idModule/:idSubModule" component={DashboardPage} /> */}
        <Route exact path="/dashboard-cafeteria" component={CafeteriaPage} />
        <Route
          exact
          path="/dashboard-settlements"
          component={SettlementsPage}
        />
        <Route
          exact
          path="/dashboard-settlements/:idSettlement"
          component={SettlementUserPage}
        />
        <Route exact path="/dashboard-reports" component={ReportsPage} />
        <Route exact path="/dashboard-reports-queries" component={ReportQueryPage} />
        <Route exact path="/dashboard-incidents" component={IncidentsPage} />
        <Route exact path="/dashboard-cfdis" component={CFDIsPage} />
        <Route exact path="/dashboard-cfdis-file/:fileId" component={CFDIsFilePage} />
        <Route exact path="/dashboard-cms" component={CmsPage} />
        <Route exact path="/dashboard-stamp-balances" component={CFDIStampBalancePage} />
        <Route
          exact
          path="/change-password-from-user"
          component={ChangePasswordFromUserPage}
        />
        <Route
          exact
          path="/succes-password-from-user"
          component={SuccessChangePaswordPage}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
